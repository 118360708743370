import {BASE_PATH} from "../utils/Contants";
import {state} from "../Main";
import {PROJECT_INDEX_SELECT} from "../templates/ProjectIndexPage";

export class ProjectIndexItem {

	public element: HTMLElement;
	public data: any;

	constructor(projectData, activeCategory) {
		this.data = projectData;

		//award tag
		let isAward = '';
		projectData.students.forEach((student) => {
			if (student.awards) {
				isAward = 'show-award';
			}
		});
		if (projectData.awards) {
			isAward = 'show-award';
		}

		const hasTheme = projectData.groupName && projectData.unit.programme.slug == 'bio';

		//title
		const div = document.createElement('div');
		let year = projectData.students[0].year;//.split(', ');
		//year = year.length > 1 ? year[1] : '';

		let subtitle = projectData.programme.title + ' ' + projectData.programme.qualification;
		if (projectData.unit.code &&
			projectData.unit.programme.slug != 'bio' &&
			projectData.unit.programme.slug != 'mahue') {
			subtitle += ', ' + projectData.unit.code;
		}

		if (year) {
			subtitle += ', ' + year;
		}
		if (hasTheme) {
			subtitle += ', ' + projectData.groupName;
		}

		//image
		let imgUrl = projectData.heroImageUrl;

		//student category display
		let studentAndGroup = '';
		if (activeCategory == 'students') {
			studentAndGroup = projectData.students[0].name;
			if (projectData.groupName && !hasTheme) {
				studentAndGroup += ', ' + projectData.groupName;
			}
			if (projectData.students[0].heroImageUrl) {
				imgUrl = projectData.students[0].heroImageUrl;
			}
		}
		//awards category display
		else if (activeCategory == 'awards') {
			if (projectData.students.length > 1) {
				studentAndGroup = projectData.groupName && !hasTheme ? projectData.groupName : "Group Project";
			} else {
				studentAndGroup = projectData.students[0].name;
				if (projectData.students[0].heroImageUrl) {
					imgUrl = projectData.students[0].heroImageUrl;
				}
			}
		} else {
			if (projectData.students.length > 1) {
				studentAndGroup = projectData.groupName && !hasTheme ? projectData.groupName : "Group Project";
			} else {
				studentAndGroup = projectData.students[0].name;
			}
		}

		div.innerHTML = `
			<div class="project-index-item ${isAward}">
			<a href="${BASE_PATH + projectData.unit.slug + '/' + projectData.slug}">
				<div class="media-wrapper" style="padding-bottom: ${(9/16) * 100}%">
					<div>
						<img class="lazyload" data-src="${imgUrl + '?w=400&fm=jpg'}">
					</div>
					<span class="award">AWARD</span>
				</div>
				<div class="info-wrapper">
					<span class="title">${projectData.title}</span>
					<span class="student">${studentAndGroup}</span>
					<span class="subtitle">${subtitle}</span>
				</div>
			</a>
			</div>
		`;

		this.element = div.firstElementChild as HTMLElement;

		this.element.querySelector('a').addEventListener('click', () => {
			state.setValue(PROJECT_INDEX_SELECT, true);
		});
	}
}
