import gsap from 'gsap';
import { View } from './View';
import { state } from '../Main';
import { CURRENT_OVERLAY } from '../utils/Contants';

class MainNav extends View {
	private activeItem: Element;

	constructor(element: HTMLElement) {
		super(element);
	}

	initialize() {
		state.on(CURRENT_OVERLAY, stateItem => {
			if (this.activeItem) {
				this.activeItem.classList.remove('active');
			}

			if (stateItem.current) {
				this.activeItem = this.element.querySelector('#' + stateItem.current.id);
			}

			if (this.activeItem) {
				this.activeItem.classList.add('active');
			}
		});
	}
}

export const mainNav = new MainNav(document.querySelector('.MainNav'));
