import { AudioLoader, Color, Mesh, MeshBasicMaterial, Object3D, PositionalAudio, Vector2, AudioListener, Audio, PerspectiveCamera } from 'three';
import { camera, gallery3d, scene } from './Gallery3d';
import * as THREE from 'three';
import { MediaMaterial } from './MediaMaterial';
import gsap from 'gsap';
import { clamp, getCameraDistanceToFill, getPointInBetweenByLen, isTouch, shortestAngle, shuffleArray } from '../../utils/Helpers';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { showData } from '../ShowData';
import { router, state } from '../../Main';
import {
	BASE_PATH,
	CLIENT_MOVE, COLOR_BACKGROUND,
	CURRENT_3D_VIEW,
	LOAD_COMPLETE,
	LOAD_PROGRESS,
	NEXT_PROJECT_PAGE,
	PREV_PROJECT_PAGE, ROOM,
} from '../../utils/Contants';
import { roomProjectMenu } from './RoomProjectMenu';
import { CURSOR_DEFAULT_3D, CURSOR_HOVER_3D, CURSOR_PLUS_3D, CursorManager, cursorManager } from '../CursorManager';
import { Point } from '../../utils/Point';
import { WindowManager } from '../../utils/WindowManager';
import { multiuserPusher } from '../MultiuserPusher';
import { Viewer } from './Viewer';
import Emitter from '@hellomonday/emitter';
import { unitBrowser } from './UnitBrowser';
import { mainMenu } from '../MainMenu';
import { audioController, WHOOSH } from '../../components/AudioController';
import { DeviceOrientationControls } from 'three/examples/jsm/controls/DeviceOrientationControls';
import {colorizer, THEME_DARK, THEME_LIGHT} from "../Colorizer";

const MAX_VIEWERS: number = 20;
const MAX_AUTOPLAY_VIDEOS: number = 3;
const ROOM_SETTINGS = {
	'Autumn Room' : {
		file: 'room_config_v1_3.glb',
		hoverRotation: 1.4
	},
	'Group Project Room 4 sides' : {
		file: 'room_config_v2_1.glb',
		hoverRotation: 2
	},
	'Group Project Room 5 sides' : {
		file: 'room_config_v3_1.glb',
		hoverRotation: 2
	},
	'PhD-Fifteen Room' : {
		file: '15phd_room_config_v1_8.glb',
		hoverRotation: 1.4
	}
}
const DEFAULT_ROOM_ID: string = 'Fifteen room v1';

class Room3d extends Emitter {
	public container: Object3D;
	public currentIndex: number = 0;
	public showing: boolean;
	public pendingAction: string;
	private dummyCamera: PerspectiveCamera;
	private targetMousePosition: Vector2 = new Vector2(0, 0);
	private currentMousePosition: Vector2 = new Vector2(0, 0);
	private hoveredIndex: number;
	private mouseRotationAmount: number = 0;
	private loadedIndex: number = 0;
	private cameraRotY: number = 0;
	private activeWallCount: number = 0;
	private masterVolume: number = 0;
	private floorPlanLoaded: boolean;
	private disableClick: boolean;
	private over3d: boolean;
	private zoomed: boolean;
	private muted: boolean = false;
	private walls: Object3D;
	private cams: Object3D;
	private art: Object3D;
	private audioMarkers: Object3D;
	private ambientArtRight: Object3D;
	private ambientArtLeft: Object3D;
	private ambientWallsRight: Object3D;
	private ambientWallsLeft: Object3D;
	private camStart: Object3D;
	private currentPlan: Object3D;
	private cone: Mesh;
	private viewersContainer: Object3D = new Object3D();
	private raycaster = new THREE.Raycaster();
	private mediaMaterials: Array<MediaMaterial> = [];
	private mediaMaterialsLeft: Array<MediaMaterial> = [];
	private mediaMaterialsRight: Array<MediaMaterial> = [];
	private unitData: any;
	private defaultBackgroundColor: string =  COLOR_BACKGROUND;
	private defaultWallColor: string = COLOR_BACKGROUND;
	private exitButton: HTMLElement;
	private galleryNav: HTMLElement;
	private otherVisitors: HTMLElement;
	private otherVisitorsCounter: HTMLElement;
	private muteButton: HTMLElement;
	private onClick: any = this._onClick.bind(this);
	private onKeyDown: any = this._onKeyDown.bind(this);
	private onMouseEnter: any = this._onMouseEnter.bind(this);
	private onMouseLeave: any = this._onMouseLeave.bind(this);
	private onTouchMove: any = this._onTouchMove.bind(this);
	private onTouchStart: any = this._onTouchStart.bind(this);
	private onGalleryMouseMove: any = this._onGalleryMouseMove.bind(this);
	private onWindowMouseMove: any = this._onWindowMouseMove.bind(this);
	private onBlur: any = this._onBlur.bind(this);
	private onFocus: any = this._onFocus.bind(this);
	private orientationControls: DeviceOrientationControls;
	private orientationEnabled: boolean;
	private lastTouchRotY: number = 0;
	private interval;
	private viewers = {};
	private pusherChannel;
	private audioListener: AudioListener;
	private ambientMusicPlaying: boolean;
	private autoPlayWalls: Array<number> = [];
	private windowBlurred: boolean;
	private viewerMouseMoved: boolean;
	private roomSettings: any;
	private isTouch: boolean = isTouch();

	public initialize() {
		this.container = new Object3D();
		this.container.add(this.viewersContainer);

		this.galleryNav = gallery3d.element.querySelector('.gallery-nav');
		this.otherVisitors = this.galleryNav.querySelector('.other-visitors');
		this.otherVisitorsCounter = this.galleryNav.querySelector('.visitor-counter');
		this.exitButton = gallery3d.element.querySelector('.exit-gallery-button');
		this.exitButton.addEventListener('click', e => {
			unitBrowser.pendingAction = this.unitData.slug;
		});

		this.muteButton = gallery3d.element.querySelector('.mute-button');
		this.muteButton.addEventListener('click', e => {
			this.toggleMute(e);
		});

		multiuserPusher.pusher.connection.bind('error', err => {
			console.log('detected PUSHER error, end Polling', err);
			this.endPollPosition();
		});
	}

	private setupPusher() {
		this.pusherChannel = multiuserPusher.pusher.subscribe('presence-channel-' + this.unitData.slug);

		this.pusherChannel.bind(CLIENT_MOVE, (data, metadata) => {
			this.onClientViewerMove(data, metadata);
		});

		this.pusherChannel.bind('pusher:member_added', member => {
			//
		});

		this.pusherChannel.bind('pusher:member_removed', member => {
			this.removeViewer(member.id);
		});
	}

	public async load(slug: string) {
		if (this.isSlugLoaded(slug)) {
			return false;
		}

		cursorManager.showLoading();
		this.unitData = showData.getUnitBySlug(slug);
		this.mediaMaterials = [];
		this.mediaMaterialsLeft = [];
		this.mediaMaterialsRight = [];
		this.autoPlayWalls = [];

		// if no unit data, the slug is a project, load single wall
		if (!this.unitData) {
			const projectData = showData.getProjectBySlug(slug);
			this.roomSettings = ROOM_SETTINGS[projectData.unit.roomConfiguration || DEFAULT_ROOM_ID];
			await this.loadFloorplan();

			if (!projectData) {
				console.warn('Room3d: Project data not found', slug);
				return;
			}
			this.currentIndex = projectData.index;
			this.activeWallCount = this.currentIndex + 1;
			await this.loadToWall(projectData.index, projectData);
			this.loadComplete();
			return true;
		}
		// unit has been found
		else {
			this.roomSettings = ROOM_SETTINGS[this.unitData.roomConfiguration || DEFAULT_ROOM_ID];
			await this.loadFloorplan();

			if (this.activeWallCount == 0) {
				this.currentIndex = 0;
			}
			this.setupPusher();
			this.loadedIndex = this.activeWallCount = 0;
			for (let i = 0; i < this.art.children.length; i++) {
				const child = this.art.children[i];
				if (child instanceof THREE.Mesh) {
					const projectData = this.unitData.projects[this.loadedIndex];
					if (projectData) {
						this.activeWallCount++;
						await this.loadToWall(this.loadedIndex, projectData);
					}
					this.loadedIndex++;
					this.emit(LOAD_PROGRESS, { progress: this.loadedIndex / this.art.children.length });
					if (this.loadedIndex == this.art.children.length) {
						roomProjectMenu.initialize(this.unitData);
						this.loadComplete();
					}
				}
			}
			return true;
		}
	}

	private loadComplete() {
		this.updateWallColors();

		for (let i = this.activeWallCount; i < this.art.children.length; i++) {
			const art: Mesh = this.art.children[i] as Mesh;
			const mediaMaterial: MediaMaterial = art.material as MediaMaterial;
			mediaMaterial.clear();
			art.visible = false;
			this.walls.children[i].visible = false;

			if (this.ambientArtLeft) {
				const ambientArtLeft: Mesh = this.ambientArtLeft.children[i] as Mesh;
				const mediaMaterialLeft: MediaMaterial = ambientArtLeft.material as MediaMaterial;
				mediaMaterialLeft.clear();
				ambientArtLeft.visible = false;
				this.ambientWallsLeft.children[i].visible = false;
			}

			if (this.ambientArtRight) {
				const ambientArtRight: Mesh = this.ambientArtRight.children[i] as Mesh;
				const mediaMaterialRight: MediaMaterial = ambientArtRight.material as MediaMaterial;
				mediaMaterialRight.clear();
				ambientArtRight.visible = false;
				this.ambientWallsRight.children[i].visible = false;
			}
		}

		shuffleArray(this.autoPlayWalls);
		this.autoPlayWalls = this.autoPlayWalls.slice(0, MAX_AUTOPLAY_VIDEOS);

		this.container.visible = true;
		cursorManager.hideLoading();
		this.emit(LOAD_COMPLETE, {});
	}

	private isSlugLoaded(slug: string) {
		// return if requested slug is already loaded
		if (this.unitData) {
			if (this.unitData.slug == slug) {
				return true;
			} else {
				for (let i = 0; i < this.unitData.projects.length; i++) {
					if (this.unitData.projects[i].slug == slug) {
						return true;
					}
				}
			}
		}
		return false;
	}

	private loadFloorplan() {
		return new Promise(resolve => {
			if (this.floorPlanLoaded) {
				resolve();
				return;
			}
			const gltf_loader = new GLTFLoader();
			this.floorPlanLoaded = true;
			gltf_loader.load('/assets/models/' + this.roomSettings.file, (obj: any) => {
				this.parseFloorplan(obj.scene);
				resolve();
			});
		});
	}

	private parseFloorplan(plan: Object3D) {
		this.cams = plan.getObjectByName('cams');
		this.walls = plan.getObjectByName('walls');
		this.art = plan.getObjectByName('art');
		this.audioMarkers = plan.getObjectByName('audio_markers');
		this.audioMarkers.children.forEach(soundContainer => {
			soundContainer.visible = false;
		});

		// remopve extra projects that dont fit the plan size
		if (this.unitData) {
			this.unitData.projects.splice(this.art.children.length);
		}

		this.ambientWallsRight = plan.getObjectByName('ambient_walls_right');
		this.ambientWallsLeft = plan.getObjectByName('ambient_walls_left');
		this.ambientArtRight = plan.getObjectByName('ambient_art_right');
		this.ambientArtLeft = plan.getObjectByName('ambient_art_left');

		this.camStart = plan.getObjectByName('cam_start');
		this.camStart.visible = false;

		this.cone = plan.getObjectByName('cone') as Mesh;
		this.cone.visible = false;

		this.cams.traverse(child => {
			child.visible = false;
		});

		// assign all art with a media material
		this.art.traverse(child => {
			if (child instanceof THREE.Mesh) {
				child.material = new MediaMaterial();
			}
		});

		// assign all ambient art left with a media material
		if (this.ambientArtLeft) {
			this.ambientArtLeft.traverse(child => {
				if (child instanceof THREE.Mesh) {
					child.material = new MediaMaterial();
				}
			});
		}

		// assign all ambient art right with a media material
		if (this.ambientArtRight) {
			this.ambientArtRight.traverse(child => {
				if (child instanceof THREE.Mesh) {
					child.material = new MediaMaterial();
				}
			});
		}

		if (this.currentPlan) {
			this.container.remove(this.currentPlan);
		}
		this.currentPlan = plan;
		this.container.add(plan);
	}

	private async loadToWall(index: number, projectData: any) {
		if (!projectData) {
			return;
		}

		const art: Mesh = this.art.children[index] as Mesh;
		const mediaMaterial: MediaMaterial = art.material as MediaMaterial;
		const isPhone = this.isTouch;
		const imageUrl = projectData.heroImageUrl + (isPhone ? '?w=1024&h=512&fit=scale&fm=jpg' : '?w=2048&h=1024&fit=scale&fm=jpg');
		const vimeoUrl = isPhone ? projectData.heroVimeoUrl_960 : projectData.heroVimeoUrl_1920;
		this.art.children[index].visible = true;
		this.walls.children[index].visible = true;
		if (vimeoUrl) {
			this.autoPlayWalls.push(index);
			await mediaMaterial.loadVideo(vimeoUrl, false);
			if (!mediaMaterial.videoLoaded) {
				console.log('ROOM: no video has loaded - load the image', imageUrl);
				await mediaMaterial.loadImage(imageUrl, false);
			}
		} else {
			await mediaMaterial.loadImage(imageUrl, false);
		}
		this.mediaMaterials.push(mediaMaterial);

		const ambientLeftUrl = projectData.ambientHeroImageLeft;
		if (ambientLeftUrl && this.ambientArtLeft) {
			this.ambientArtLeft.children[index].visible = true;
			this.ambientWallsLeft.children[index].visible = true;
			const artLeft: Mesh = this.ambientArtLeft.children[index] as Mesh;
			const mediaMaterialLeft: MediaMaterial = artLeft.material as MediaMaterial;
			await mediaMaterialLeft.loadImage(ambientLeftUrl, false);
			this.mediaMaterialsLeft.push(mediaMaterialLeft);
		}

		const ambientRightUrl = projectData.ambientHeroImageRight;
		if (ambientRightUrl && this.ambientArtRight) {
			this.ambientArtRight.children[index].visible = true;
			this.ambientWallsRight.children[index].visible = true;
			const artRight: Mesh = this.ambientArtRight.children[index] as Mesh;
			const mediaMaterialRight: MediaMaterial = artRight.material as MediaMaterial;
			await mediaMaterialRight.loadImage(ambientRightUrl, false);
			this.mediaMaterialsRight.push(mediaMaterialRight);
		}
	}

	private updateWallColors() {
		// update wall color and material
		const wallColor = '#' + (this.unitData && this.unitData.wallBackgroundColor ? this.unitData.wallBackgroundColor : this.defaultWallColor);
		this.walls.traverse(child => {
			if (child instanceof THREE.Mesh) {
				child.material = new MeshBasicMaterial({
					color: new Color(wallColor)
				});
			}
		});

		// update ambient wall left color and material
		if (this.ambientWallsLeft) {
			this.ambientWallsLeft.traverse(child => {
				if (child instanceof THREE.Mesh) {
					child.material = new MeshBasicMaterial({
						color: new Color(wallColor)
					});
				}
			});
		}

		// update ambient wall right color and material
		if (this.ambientWallsRight) {
			this.ambientWallsRight.traverse(child => {
				if (child instanceof THREE.Mesh) {
					child.material = new MeshBasicMaterial({
						color: new Color(wallColor)
					});
				}
			});
		}
	}

	public connectDeviceOrientation() {
		this.dummyCamera = new PerspectiveCamera();
		this.orientationControls = new DeviceOrientationControls(this.dummyCamera);
	}

	private addEvents() {
		this.removeEvents();
		gallery3d.webglContainer.addEventListener('click', this.onClick);
		gallery3d.webglContainer.addEventListener('mouseenter', this.onMouseEnter);
		gallery3d.webglContainer.addEventListener('mouseleave', this.onMouseLeave);
		gallery3d.webglContainer.addEventListener('touchmove', this.onTouchMove);
		gallery3d.webglContainer.addEventListener('touchstart', this.onTouchStart);
		document.addEventListener('keydown', this.onKeyDown);
		gallery3d.webglContainer.addEventListener('mousemove', this.onGalleryMouseMove);
		addEventListener('mousemove', this.onWindowMouseMove);
		addEventListener('focus', this.onFocus);
		addEventListener('blur', this.onBlur);
	}

	private removeEvents() {
		gallery3d.webglContainer.removeEventListener('click', this.onClick);
		gallery3d.webglContainer.removeEventListener('mouseenter', this.onMouseEnter);
		gallery3d.webglContainer.removeEventListener('mouseleave', this.onMouseLeave);
		gallery3d.webglContainer.removeEventListener('touchmove', this.onTouchMove);
		gallery3d.webglContainer.removeEventListener('touchstart', this.onTouchStart);
		document.removeEventListener('keydown', this.onKeyDown);
		gallery3d.webglContainer.removeEventListener('mousemove', this.onGalleryMouseMove);
		removeEventListener('mousemove', this.onWindowMouseMove);
		removeEventListener('focus', this.onFocus);
		removeEventListener('blur', this.onBlur);
	}

	private _onClick(event) {
		this.onWallSelect();
	}

	private _onMouseEnter(event) {
		this.over3d = true;
		cursorManager.setType(CURSOR_DEFAULT_3D);
	}

	private _onMouseLeave(event) {
		this.over3d = false;
		cursorManager.setType();
	}

	private _onTouchStart(event) {
		this.lastTouchRotY = event.changedTouches[0].clientX;
	}

	private _onTouchMove(event) {
		const delta = clamp(-0.1, 0.1, (event.changedTouches[0].clientX - this.lastTouchRotY) / 100);
		this.cameraRotY += delta;
		this.cameraRotY = this.cameraRotY % (Math.PI * 2);
		this.lastTouchRotY = event.changedTouches[0].clientX;
	}

	private _onGalleryMouseMove(event) {
		this.targetMousePosition.x = (event.clientX / WindowManager.width) * 2 - 1;
		this.targetMousePosition.y = -(event.clientY / WindowManager.height) * 2 + 1;
	}

	private _onWindowMouseMove(event) {
		cursorManager.move(new Point(event.clientX, event.clientY));
		this.viewerMouseMoved = true;
	}

	private _onFocus() {
		this.windowBlurred = false;
		if (!this.muted) {
			this.playSpatialMusic();
		}
	}

	private _onBlur() {
		this.windowBlurred = true;
		this.pauseSpatialMusic(0);
	}

	private checkCursor() {
		if (this.over3d && !this.zoomed) {
			const selectedArt = this.getSelectedArtIndex();
			if (selectedArt != null && this.unitData) {
				if (selectedArt.index == this.currentIndex) {
					cursorManager.setType(CURSOR_PLUS_3D);
				} else {
					cursorManager.setType(CURSOR_HOVER_3D);
				}
			} else {
				cursorManager.setType(CURSOR_DEFAULT_3D);
			}
		}
	}

	private _onKeyDown(event) {
		if (this.disableClick || this.zoomed) {
			return;
		}
		if (event.keyCode == 37) {
			this.gotoPrev();
		} else if (event.keyCode == 39) {
			this.gotoNext();
		}
	}

	private async onWallSelect() {
		if (this.disableClick) {
			return;
		}
		const selectedArt = this.getSelectedArtIndex();
		if (selectedArt != null) {
			if (selectedArt.index != this.currentIndex) {
				this.gotoPosition(selectedArt.index, 1.5, null, true);
				this.enableLookAround(0.5);
			} else {
				if (!this.zoomed) {
					this.gotoProjectPage();
				}
			}
		}
	}

	private async gotoProjectPage() {
		this.pendingAction = null;
		cursorManager.showLoading();
		mainMenu.animateOut();
		const project = this.unitData.projects[this.currentIndex];
		if (project) {
			router.route(BASE_PATH + project.unit.slug + '/' + project.slug);
		} else {
			cursorManager.hideLoading();
		}
	}

	public resize() {
		if (!this.showing) {
			return;
		}
		roomProjectMenu.resize();
		if (this.zoomed) {
			this.zoom(0);
		}
	}

	//todo: break this function out - its huge
	public async animateIn(selected: boolean) {
		let resolveDelay = 0;
		const wasHidden = !this.showing;
		this.show();

		const bgColor = '#' + (this.unitData && this.unitData.roomBackgroundColor ? this.unitData.roomBackgroundColor : this.defaultBackgroundColor);
		gallery3d.setBackground(bgColor);

		this.disableClick = false;
		this.orientationEnabled = false;

		if (this.orientationControls) {
			this.orientationControls.enabled = true;
		}

		//animate in on a selected wall (project page view)
		if (selected) {
			this.hideGalleryNav();
			//single wall setup
			if (!this.unitData) {
				resolveDelay = 0;
				this.gotoPosition(this.currentIndex, 0);
				this.zoom(0);
			}
			//multi-wall
			else {
				const index = showData.getProjectBySlug(state.getValue(CURRENT_3D_VIEW).slug).index;
				if (index != this.currentIndex) {
					resolveDelay = 3;
					this.gotoPosition(index, 2, () => {
						this.zoom(1);
					});
				} else {
					resolveDelay = 1;
					this.zoom(1);
				}
			}
		} else {
			this.targetMousePosition.set(0, 0);
			this.currentMousePosition.set(0, 0);

			//go directly to next wall and zoom (next arrow has been clicked)
			if (this.pendingAction == NEXT_PROJECT_PAGE) {
				resolveDelay = 1.5;
				this.gotoNext();
			}
			//go directly to prev wall and zoom (prev arrow has been clicked)
			else if (this.pendingAction == PREV_PROJECT_PAGE) {
				resolveDelay = 1.5;
				this.gotoPrev();
			}
			//standard animate in
			else {
				resolveDelay = 1;

				//animate in on the room, zoomed out
				if (wasHidden) {
					camera.position.copy(this.camStart.position);
				}

				this.autoPlayWalls.forEach(index => {
					const art: Mesh = this.art.children[index] as Mesh;
					(art.material as MediaMaterial).playVideo();
				});

				//todo: should come from Unit CMS?
				//colorizer.changeColor(THEME_DARK);
				colorizer.changeColor(THEME_LIGHT);

				this.gotoPosition(this.currentIndex, 2, () => {
					if (!mainMenu.animatedIn) {
						roomProjectMenu.animateIn();

						if (isTouch() && this.showing) {
							cursorManager.setType(CURSOR_DEFAULT_3D);
						}
					}
					this.showGalleryNav();
					mainMenu.animateInToggle();
					if (this.orientationControls) {
						this.orientationEnabled = true;
					}
				});

				this.fadeMasterVolume(0, null, 0);
				this.playSpatialMusic();

				this.enableLookAround(1, 2);
			}
		}

		return new Promise(resolve => {
			if (wasHidden) {
				gsap.to(gallery3d.webglContainer, 2, {
					opacity: 1,
					delay: 0.5,
					onComplete: () => {
						resolve();
					}
				});
			} else {
				gsap.delayedCall(resolveDelay, () => {
					resolve();
				});
			}
		});
	}

	public animateOut() {
		if (!this.showing) {
			return;
		}
		gsap.killTweensOf([camera.position, this]);
		roomProjectMenu.animateOut();
		this.pauseSpatialMusic(1);

		return new Promise(resolve => {
			this.hideGalleryNav();

			gsap.to(gallery3d.webglContainer, 0.5, {
				opacity: 0,
				onComplete: () => {
					this.hide();
					resolve();
				}
			});
		});
	}

	private hideGalleryNav() {
		gsap.to(this.galleryNav, 0.5, { autoAlpha: 0 });
		mainMenu.animateOutToggle();
	}

	private showGalleryNav() {
		gsap.to(this.galleryNav, 0.5, { autoAlpha: 1 });
		mainMenu.animateInToggle();
	}

	public show() {
		if (!this.showing) {
			this.showing = true;
			this.addEvents();
			scene.add(this.container);
			this.resize();
		}
	}

	public hide() {
		if (this.showing) {
			this.showing = false;
			this.floorPlanLoaded = false;
			this.removeEvents();
			this.pendingAction = null;
			this.targetMousePosition.set(0, 0);
			if (this.orientationControls) {
				this.orientationControls.enabled = false;
			}
			this.disableLookAround(0);
			this.activeWallCount = 0;
			this.autoPlayWalls = [];
			this.currentIndex = 0;
			this.hoveredIndex = 0;
			this.cameraRotY = 0;
			this.unitData = null;
			this.clearViewers();
			for (let i = 0; i < this.mediaMaterials.length; i++) {
				this.mediaMaterials[i].clear();
			}
			for (let i = 0; i < this.mediaMaterialsLeft.length; i++) {
				this.mediaMaterialsLeft[i].clear();
			}
			for (let i = 0; i < this.mediaMaterialsRight.length; i++) {
				this.mediaMaterialsRight[i].clear();
			}
			scene.remove(this.container);
			this.endPollPosition();
		}
	}

	public render() {
		if (!this.showing) {
			return;
		}

		this.mediaMaterials.forEach(mediaMaterial => {
			mediaMaterial.render();
		});

		this.checkCursor();
		roomProjectMenu.render();

		if (this.isTouch) {
			if (this.orientationControls) {
				this.orientationControls.update();
				this.orientationControls.alphaOffset = this.cameraRotY;
			}

			let targetRotX = 0;
			let targetRotZ = 0;
			let targetRotY = this.cameraRotY;
			if (this.orientationEnabled) {
				targetRotX = this.dummyCamera.rotation.x;
				targetRotZ = this.dummyCamera.rotation.z;
				targetRotY = this.dummyCamera.rotation.y; // + degreesToRadians(this.orientationControls.screenOrientation);
			}

			camera.rotation.x += shortestAngle(targetRotX - camera.rotation.x) / 15;
			camera.rotation.y += shortestAngle(targetRotY - camera.rotation.y) / 15;
			camera.rotation.z += shortestAngle(targetRotZ - camera.rotation.z) / 15;
		} else {
			camera.rotation.y = this.cameraRotY;
		}

		if (!this.isTouch) {
			this.currentMousePosition.y += (this.targetMousePosition.y - this.currentMousePosition.y) / 10;
			this.currentMousePosition.x -= (this.targetMousePosition.x + this.currentMousePosition.x) / 10;
			camera.rotation.x = (this.currentMousePosition.y / 2) * this.mouseRotationAmount;
			camera.rotation.y += this.currentMousePosition.x * this.roomSettings.hoverRotation * this.mouseRotationAmount;
		}

		camera.rotation.y = camera.rotation.y % (Math.PI * 2);
	}

	private startPollPosition() {
		if (this.interval || !this.pusherChannel) {
			return;
		}
		this.interval = setInterval(() => {
			if (!this.windowBlurred && this.viewerMouseMoved) {
				// && !this.zoomed
				this.pusherChannel.trigger(CLIENT_MOVE, {
					x: camera.rotation.x,
					y: camera.rotation.y,
					unit: this.unitData.slug,
					projectIndex: this.currentIndex
				});
			}
			this.viewerMouseMoved = false;
		}, 1500);
	}

	private endPollPosition() {
		if (this.interval) {
			clearInterval(this.interval);
			this.interval = null;
		}
	}

	public gotoNext() {
		let index = this.currentIndex + 1;
		if (index > this.activeWallCount - 1) {
			index = 0;
		}

		this.gotoPosition(
			index,
			1.5,
			() => {
				if (this.pendingAction == NEXT_PROJECT_PAGE) {
					this.gotoProjectPage();
				}
			},
			true
		);
	}

	public gotoPrev() {
		let index = this.currentIndex - 1;
		if (index < 0) {
			index = this.activeWallCount - 1;
		}
		this.gotoPosition(
			index,
			1.5,
			() => {
				if (this.pendingAction == PREV_PROJECT_PAGE) {
					this.gotoProjectPage();
				}
			},
			true
		);
	}

	public gotoPosition(index: number, speed: number = 1.5, onComplete: Function = null, playSound: boolean = false) {
		if (this.disableClick || index > this.activeWallCount || this.mediaMaterials.length == 0) {
			return;
		}

		if (this.currentIndex > -1) {
			const lastArt: Mesh = this.art.children[this.currentIndex] as Mesh;
			if (!this.autoPlayWalls.includes(this.currentIndex)) {
				(lastArt.material as MediaMaterial).pauseVideo();
			}
		}

		if (playSound && this.currentIndex != index) {
			audioController.play(WHOOSH);
		}

		this.currentIndex = index;
		this.disableClick = true;
		this.zoomed = false;

		roomProjectMenu.select(this.currentIndex);
		this.startPollPosition();

		const cam = this.cams.children[index];
		const art: Mesh = this.art.children[index] as Mesh;
		gsap.to(camera.position, speed, {
			x: cam.position.x,
			y: cam.position.y,
			z: cam.position.z,
			ease: 'Power2.easeInOut',
			onComplete: () => {
				(art.material as MediaMaterial).playVideo();
				this.disableClick = false;
				if (onComplete) {
					onComplete();
				}
			}
		});

		this.rotateCameraToTarget(index, speed);
	}

	private rotateCameraToTarget(index: number, speed: number = 1.5) {
		const cam = this.cams.children[index];
		const art: Mesh = this.art.children[index] as Mesh;

		let rotY = Math.atan2(cam.position.x - art.position.x, cam.position.z - art.position.z);
		let cameraRotDeltaY = this.cameraRotY - rotY;

		if (this.orientationControls && this.orientationEnabled) {
			cameraRotDeltaY += this.dummyCamera.rotation.y - this.orientationControls.alphaOffset;
		} else {
			cameraRotDeltaY = cameraRotDeltaY % (Math.PI * 2);
		}

		cameraRotDeltaY = shortestAngle(cameraRotDeltaY);

		gsap.to(this, speed, {
			cameraRotY: '-=' + cameraRotDeltaY,
			ease: 'Power2.easeInOut'
		});
	}

	public dim() {
		gsap.to(gallery3d.element, 0.5, { opacity: 0.4 });
	}

	public undim() {
		gsap.to(gallery3d.element, 0.5, { opacity: 1 });
	}

	public disableLookAround(speed: number = 0.5) {
		gsap.to(this, speed, { mouseRotationAmount: 0 });
	}

	public enableLookAround(speed: number = 0.5, delay: number = 0, onComplete = null) {
		if (this.zoomed) {
			return;
		}
		gsap.to(this, speed, {
			mouseRotationAmount: 1,
			delay: delay,
			onComplete: () => {
				if (onComplete) {
					onComplete();
				}
			}
		});
	}

	private zoom(speed: number = 1) {
		const art = this.art.children[this.currentIndex];
		const distance = getCameraDistanceToFill(camera, art);
		const newPt = getPointInBetweenByLen(art.position, camera.position, distance);

		if (!this.zoomed) {
			if (this.orientationControls) {
				this.orientationEnabled = false;
				this.rotateCameraToTarget(this.currentIndex, 0);
			}
			this.disableLookAround(speed);
			roomProjectMenu.animateOut();
			this.pauseSpatialMusic(1);
			this.zoomed = true;
		}

		return new Promise(resolve => {
			gsap.to(camera.position, speed, {
				x: newPt.x,
				y: newPt.y,
				z: newPt.z,
				onComplete: () => {
					resolve();
				}
			});
		});
	}

	private getSelectedArtIndex() {
		if (this.floorPlanLoaded) {
			this.raycaster.setFromCamera(this.targetMousePosition, camera);
			let intersects = this.raycaster.intersectObjects(this.art.children);
			let ambientWall = false;
			if (!intersects.length) {
				intersects = this.raycaster.intersectObjects(this.walls.children);
			}
			if (!intersects.length && this.ambientArtLeft && this.ambientArtRight) {
				intersects = this.raycaster.intersectObjects([...this.ambientArtLeft.children, ...this.ambientArtRight.children]);
				ambientWall = true;
			}
			if (!intersects.length && this.ambientWallsLeft && this.ambientWallsRight) {
				intersects = this.raycaster.intersectObjects([...this.ambientWallsLeft.children, ...this.ambientWallsRight.children]);
				ambientWall = true;
			}
			if (intersects.length) {
				const mesh = intersects[0].object as Mesh;
				if (mesh.visible) {
					return {index: parseInt(mesh.name) - 1, isAmbient: ambientWall};
				} else {
					return null;
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	private clearViewers() {
		Object.keys(this.viewers).forEach(userId => {
			this.removeViewer(userId);
		});
	}

	private addViewer(userId) {
		if (Object.keys(this.viewers).length > MAX_VIEWERS) {
			//could emit OVER_MAX event here with userId for this user to stop polling
			return;
		}
		const viewer = new Viewer(this.cone.clone(true));
		this.viewers[userId] = viewer;
		this.viewersContainer.add(viewer.mesh);
		this.updateViewerCount();
		return viewer;
	}

	private removeViewer(userId) {
		const viewer = this.viewers[userId];
		if (viewer) {
			this.viewersContainer.remove(viewer.mesh);
			delete this.viewers[userId];
			this.updateViewerCount();
		}
	}

	private updateViewerCount() {
		if (this.viewersContainer.children.length == 0) {
			this.otherVisitors.style.display = 'none';
		} else {
			this.otherVisitors.style.display = 'flex';
		}
		this.otherVisitorsCounter.innerText = String(this.viewersContainer.children.length);
	}

	private onClientViewerMove(data, metadata) {
		// const member = multiuserPusher.channel.members.get(metadata.user_id);
		if (this.showing && this.unitData && this.cams) {
			// viewer is in the unit
			if (data.unit == this.unitData.slug) {
				// new viewer
				if (!this.viewers[metadata.user_id]) {
					const viewer = this.addViewer(metadata.user_id);
					const position = this.cams.children[data.projectIndex].position;
					gsap.set(viewer.mesh.position, {
						x: position.x + viewer.offset.x,
						y: position.y + viewer.offset.y,
						z: position.z + viewer.offset.z
					});
				}

				// existing viewer
				else {
					const viewer = this.viewers[metadata.user_id];
					if (data.projectIndex != viewer.projectIndex) {
						const position = this.cams.children[data.projectIndex].position;
						gsap.to(viewer.mesh.position, 2, {
							x: position.x + viewer.offset.x,
							y: position.y + viewer.offset.y,
							z: position.z + viewer.offset.z
						});
					}
				}

				gsap.to(this.viewers[metadata.user_id].mesh.rotation, 0.3, { x: data.x, y: data.y });
			} else {
				//view has left unit
				if (this.viewers[metadata.user_id]) {
					this.removeViewer(metadata.user_id);
				}
			}
		}
	}

	public toggleMute(e) {
		e.preventDefault();
		if (e.target.classList.contains('muted')) {
			this.muted = false;
			e.target.classList.remove('muted');
			this.playSpatialMusic();
		} else {
			this.muted = true;
			e.target.classList.add('muted');
			this.pauseSpatialMusic();
		}
	}

	private async initAudioAPI() {
		this.audioListener = new AudioListener();
		camera.add(this.audioListener);
		audioController.load();
	}

	public playSpatialMusic(fromClick: boolean = false) {
		// Audio Listener not made yet
		if (!this.audioListener) {
			if (fromClick) {
				this.initAudioAPI();
			} else {
				return;
			}
		}

		if (!this.audioMarkers || !this.showing || this.zoomed || this.muted || this.ambientMusicPlaying) {
			return;
		}

		this.ambientMusicPlaying = true;

		// Audio Markers Not added yet
		if (this.audioMarkers.children[0].children.length == 0) {
			const audioLoader = new AudioLoader();
			const audio = ['01_ambient.mp3', '02_ambient.mp3', '03_ambient.mp3'];
			audio.forEach((n, index) => {
				const snd = new THREE.PositionalAudio(this.audioListener);
				this.audioMarkers.children[index].add(snd);
				audioLoader.load('/assets/audio/' + n, buffer => {
					snd.setBuffer(buffer);
					snd.setRefDistance(10);
					snd.loop = true;
					if (!snd.isPlaying) {
						//console.log('playing spatial track');
						snd.play();
					}
				});
			});
		} else {
			this.audioMarkers.children.forEach(soundContainer => {
				const posAudio = soundContainer.children[0] as PositionalAudio;
				if (!posAudio.isPlaying) {
					posAudio.play();
				}
			});
		}

		this.fadeMasterVolume(.5);
	}

	public pauseSpatialMusic(fadeSpeed: number = undefined) {
		if (!this.audioListener || !this.ambientMusicPlaying) {
			return;
		}

		this.ambientMusicPlaying = false;

		this.fadeMasterVolume(
			0,
			() => {
				if (this.audioMarkers && this.audioMarkers.children.length) {
					this.audioMarkers.children.forEach(soundContainer => {
						if (soundContainer.children.length) {
							(soundContainer.children[0] as PositionalAudio).pause();
						}
					});
				}
			},
			fadeSpeed
		);
	}

	private fadeMasterVolume(v: number, onComplete = null, speed: number = 3) {
		if (!this.audioListener) {
			return;
		}

		gsap.to(this, speed, {
			masterVolume: v,
			onUpdate: () => {
				this.audioListener.setMasterVolume(this.masterVolume);
			},
			onComplete: () => {
				if (onComplete) {
					onComplete();
				}
			},
			overwrite: 'auto'
		});
	}
}

export const room3d = new Room3d();
