interface Callback {
	(result: any): void;
}

export class Autocomplete {
	public element: HTMLElement;
	private selected: HTMLElement;
	private inputSearch: HTMLInputElement;
	private list: HTMLElement;
	private listItems: NodeListOf<HTMLElement>;
	private dropdown: HTMLElement;
	private callback: Callback;

	constructor(element: HTMLElement, callback: Callback) {
		this.element = element;
		this.callback = callback;
		this.selected = this.element.querySelector('.selected button');
		this.inputSearch = this.element.querySelector('.input.search');
		this.list = this.element.querySelector('.list');
		this.listItems = this.list.querySelectorAll('.list-item');
		this.dropdown = this.element.querySelector('.dropdown');

		this.init();
	}

	private init() {
		this.inputSearch.addEventListener('focus', e => this.toggleDropdown(e));
		//this.inputSearch.addEventListener('blur', e => this.toggleDropdown(e));
		this.addListItemHandlers();
	}

	onListItemClick(e: any) {
		const el = e.target;
		this.inputSearch.value = el.querySelector('.dropdown-title').innerHTML;
		this.callback(el.dataset.value);
		this.hideDropdown();
	}

	toggleDropdown(e: any) {
		const relTarget = e.relatedTarget;
		if (relTarget !== null && relTarget.classList.contains('list-button')) return false;

		if (this.element.classList.contains('active')) {
			this.hideDropdown();
		} else {
			this.showDropdown();
		}
	}

	public populate(values: any) {
		this.removeListItemHandlers();
		this.list.innerHTML = '';

		for (const item of values) {
			let listItem = this.setItemTemplate(item.name, item.name);
			this.list.insertAdjacentHTML('beforeend', listItem);
		}

		this.listItems = this.list.querySelectorAll('.list-item');
		this.addListItemHandlers();

		this.showDropdown();
	}

	setItemTemplate(key, label) {
		return `
				<li class="list-item">
				<button class="list-button" data-value="${key}">
				<span class="span dropdown-title type-p--small">${label}</span>
				</button>
				</li>
		`;
	}

	hideDropdown() {
		this.element.classList.remove('active');
	}

	showDropdown() {
		this.element.classList.add('active');
	}

	addListItemHandlers() {
		//console.log(this.listItems);
		this.listItems.forEach(el => {
			el.addEventListener('click', e => this.onListItemClick(e));
		}, this);
	}

	removeListItemHandlers() {
		this.listItems.forEach(el => {
			el.removeEventListener('click', e => this.onListItemClick(e));
		}, this);

		this.inputSearch.removeEventListener('focus', e => this.toggleDropdown(e));
		this.inputSearch.removeEventListener('blur', e => this.toggleDropdown(e));
	}

	public close() {
		this.hideDropdown();
	}

	public destroy() {
		this.removeListItemHandlers();
	}
}
