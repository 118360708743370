import {BASE_PATH, FIFTEEN} from '../utils/Contants';
import { introPage } from '../templates/IntroPage';
import {shuffleArray} from "../utils/Helpers";

export const INTRO_URL = BASE_PATH + 'intro-media-data-json';
export const DFM_URL = BASE_PATH + 'programme-data-json/dfm';
export const DFPI_URL = BASE_PATH + 'programme-data-json/dfpi';
export const MASP_URL = BASE_PATH + 'programme-data-json/masp';
export const SHOWS_BY_PROGRAMME = {
	march: FIFTEEN,
}

export const PROGRAMME_LABELS = {
	'': 'All',
	dfm: 'All',
	dfpi: 'All',
	masp: 'All'
}


class ShowData {
	public json: any = { show: { programmes: [], introMediaEntries: [] } };
	public students: Array<any> = [];

	public async load() {
		let response = await fetch(INTRO_URL);
		if (response.ok) {
			this.json.show.introMediaEntries = (await response.json()).entries;
		}
		introPage.progress = 0.1;
		shuffleArray(this.json.show.introMediaEntries);

		response = await fetch(DFM_URL);
		if (response.ok) {
			this.json.show.programmes.push(await response.json());
		}
		introPage.progress = 0.2;

		response = await fetch(DFPI_URL);
		if (response.ok) {
			this.json.show.programmes.push(await response.json());
		}
		introPage.progress = 0.3;

		response = await fetch(MASP_URL);
		if (response.ok) {
			this.json.show.programmes.push(await response.json());
		}
		introPage.progress = 0.4;

		this.createQuickReferences();
	}

	private createQuickReferences() {
		let unitCount = 0;
		const temptStudentDict = {};
		this.json.show.programmes.forEach(programme => {
			programme.units.forEach(unit => {
				unit.show = SHOWS_BY_PROGRAMME[programme.slug];
				unit.programme = programme;
				unit.index = unitCount;
				unit.projects.forEach((project, index) => {
					project.programme = programme;
					project.unit = unit;
					project.index = index;
					project.students.forEach((student, index) => {
						if (!temptStudentDict[student.name]) {
							this.students.push(student);
							temptStudentDict[student.name] = true;
						}
						student.project = project;
					});
				});
				unitCount++;
			});
		});
	}

	public getUnitBySlug(unitSlug: string) {
		let cUnit = null;
		this.json.show.programmes.forEach(programme => {
			programme.units.forEach(unit => {
				if (unit.slug == unitSlug) {
					cUnit = unit;
				}
			});
		});
		return cUnit;
	}

	public getProjectBySlug(projectSlug: string) {
		let cProject = null;
		this.json.show.programmes.forEach(programme => {
			programme.units.forEach(unit => {
				unit.projects.forEach(project => {
					if (project.slug == projectSlug) {
						cProject = project;
					}
				});
			});
		});
		return cProject;
	}

	public getStudents(name: string = null) {
		const results = [];
		this.students.forEach(student => {
			const sName = student.name.toLowerCase();
			if (!name || sName.indexOf(name.toLowerCase()) > -1) {
				results.push(student);
			}
		});
		return results;
	}

	public getProjectsByAwardType(awardType: string = null) {
		const results = [];
		const studentsDict = {};
		const projectsDict = {};
		this.getProjectsByProgrammeAndUnit().forEach(project => {
			//add student awards
			project.students.forEach(student => {
				if (student.awards) {
					student.awards.forEach(award => {
						if (!studentsDict[student.name] && (!awardType ||
							award.type.toLowerCase().replace(/&amp;/g, '&') == awardType.toLowerCase())) {
							const projectClone = Object.assign({}, project);
							projectClone.students = [student];
							studentsDict[student.name] = student;
							results.push(projectClone);
						}
					});
				}
			});
			//add project awards
			if (project.awards) {
				project.awards.forEach(award => {
					if (!projectsDict[project.title] && (!awardType ||
						award.type.toLowerCase().replace(/&amp;/g, '&') == awardType.toLowerCase())) {
						projectsDict[project.title] = project;
						results.push(project);
					}
				});
			}
		});
		return results;
	}

	public getProjectsByProgrammeAndUnit(programmeSlug: string = null, unitSlug: string = null, show: string = '') {
		const results = [];
		this.json.show.programmes.forEach(programme => {
			if (!show || SHOWS_BY_PROGRAMME[programme.slug.toLowerCase()] == show) {
				if (!programmeSlug || programme.slug.toLowerCase() == programmeSlug.toLowerCase()) {
					programme.units.forEach(unit => {
						unit.projects.forEach(project => {
							if (!unitSlug || unit.slug.toLowerCase() == unitSlug.toLowerCase()) {
								results.push(project);
							}
						});
					});
				}
			}
		});
		return results;
	}

	public getProjectsByProgrammeAndGroup(programmeSlug: string, groupName: string) {
		const results = [];
		this.getUnitsByProgramme(programmeSlug).forEach(unit => {
			unit.projects.forEach(project => {
				if (!groupName || project.groupName.toLowerCase() == groupName.toLowerCase()) {
					results.push(project);
				}
			});
		});
		return results;
	}

	public getProgrammesByShow(showId) {
		const results = [];

		this.json.show.programmes.forEach(programme => {
			if (!showId || SHOWS_BY_PROGRAMME[programme.slug].toLowerCase() == showId) {
				results.push(programme);
			}
		});
		return results;
	}

	public getUnitsByProgramme(programmeSlug: string = null) {
		const results = [];
		this.json.show.programmes.forEach(programme => {
			if (!programmeSlug || programme.slug.toLowerCase() == programmeSlug.toLowerCase()) {
				programme.units.forEach(unit => {
					results.push(unit);
				});
			}
		});
		return results;
	}

	public getGroupsByProgramme(programmeSlug: string = null) {
		const resultsObj = {};
		this.json.show.programmes.forEach(programme => {
			if (!programmeSlug || programme.slug.toLowerCase() == programmeSlug.toLowerCase()) {
				programme.units.forEach(unit => {
					unit.projects.forEach(project => {
						if (project.groupName) {
							resultsObj[project.groupName] = {title: project.groupName};
						}
					});
				});
			}
		});

		const results = [];
		for (let resultsObjKey in resultsObj) {
			results.push(resultsObj[resultsObjKey]);
		}
		return results;//.sort((a, b) => (a.title > b.title) ? 1 : -1)
	}

	public getProjectsByStudent(studentName: string = null) {
		const results = [];
		this.getProjectsByProgrammeAndUnit().forEach(project => {
			project.students.forEach(student => {
				if (!studentName || student.name == studentName) {
					const projectClone = Object.assign({}, project);
					projectClone.students = [student];
					results.push(projectClone);
				}
			});
		});
		return results;
	}

	public getAwardTypes() {
		const results = [];

		this.getProjectsByProgrammeAndUnit().forEach(project => {
			//add student awards
			project.students.forEach(student => {
				if (student.awards) {
					student.awards.forEach(award => {
						if(results.indexOf(award.type) === -1) {
							results.push(award.type);
						}
					});
				}
			});
			//add project awards
			if (project.awards) {
				project.awards.forEach(award => {
					if(results.indexOf(award.type) === -1) {
						results.push(award.type);
					}
				});
			}
		});

		return results;
	}
}

export const showData = new ShowData();
