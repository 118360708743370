interface Callback {
	(result: any): void;
}

export class Soundbyte {
	public element: HTMLElement;
	private selected: HTMLElement;
	private playAudioButton: HTMLElement;
	private pauseAudioButton: HTMLElement;
	public audioEl: HTMLAudioElement;
	private pauseCallback: Callback;

	constructor(element: HTMLElement, pauseCallback: Callback) {
		this.element = element;
		this.pauseCallback = pauseCallback;
		this.init();
	}

	private init() {
		this.playAudioButton = this.element.querySelector('.play');
		this.pauseAudioButton = this.element.querySelector('.pause');
		this.audioEl = this.element.querySelector('audio');

		this.audioEl.addEventListener('play', () => {
			this.element.classList.add('playing');
		});

		this.audioEl.addEventListener('pause', () => {
			this.element.classList.remove('playing');
		});

		this.playAudioButton.addEventListener('click', e => {
			this.play();
		});

		this.pauseAudioButton.addEventListener('click', e => {
			this.pause();
		});
	}

	public play() {
		this.audioEl.setAttribute('src', this.audioEl.dataset.src);
		this.audioEl.load();
		this.audioEl.play();
		this.pauseCallback({ el: this.element });
	}

	public pause() {
		this.audioEl.pause();
	}

	public destroy() {
		this.audioEl.addEventListener('play', () => {});
		this.audioEl.addEventListener('pause', () => {});
		this.playAudioButton.addEventListener('click', e => {
			this.play();
		});
		this.pauseAudioButton.addEventListener('click', e => {
			this.pause();
		});
	}
}
