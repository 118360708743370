import {colorizer, THEME_DARK, THEME_LIGHT} from "../modules/Colorizer";
import {SlidePage} from "./SlidePage";

export class ContactPage extends SlidePage {

	public element: HTMLElement;

	constructor(element) {
		super(element);
	}
}
