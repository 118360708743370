import { EditorialModule } from './EditorialModule';
import videojs from 'video.js';

export class SoundModule extends EditorialModule {
	public mediaElement: HTMLMediaElement;
	private playToggle: HTMLElement;
	private player: videojs;
	private playing: boolean = false;

	constructor(element: HTMLElement) {
		super(element);

		this.playToggle = this.element.querySelector('.title-play');
		this.mediaElement = this.element.querySelector('audio');

		this.player = videojs(this.mediaElement);

		this.mediaElement.addEventListener('play', () => {
			this.emit('pausePlayers', { el: this.mediaElement });
		});

		this.playToggle.addEventListener('click', this.onPlayToggleClick.bind(this));
	}

	private onPlayToggleClick() {
		if (this.mediaElement.paused) {
			this.play();
		} else {
			this.pause();
		}
	}

	public play() {
		this.mediaElement.play();
		this.playToggle.dataset.playing = 'true';
	}

	public pause() {
		this.mediaElement.pause();
		this.playToggle.dataset.playing = 'false';
	}

	public load() {}

	public destroy() {
		super.destroy();
		videojs(this.mediaElement).dispose();
		this.playToggle.removeEventListener('click', this.onPlayToggleClick.bind(this));
	}
}
