import gsap from "gsap";
import {View} from "../modules/View";
import {Globals} from "../utils/Globals";
import {colorizer, THEME_DARK, THEME_LIGHT} from "../modules/Colorizer";
import {SlidePage} from "./SlidePage";

export class ProgrammesPage extends SlidePage {

	public element: HTMLElement;

	constructor(element) {
		super(element);
	}

	public animateIn() {
		colorizer.changeColor(THEME_DARK);
		return super.animateIn();
	}

	public animateOut() {
		colorizer.changeColorBack();
		return super.animateOut();
	}
}
