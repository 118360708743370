import gsap from "gsap";
import {EditorialPage} from "./EditorialPage";
import {router, state} from "../Main";
import {BASE_PATH, CURRENT_OVERLAY, LAST_3D_PATH, LAST_OVERLAY, NEXT_PROJECT_PAGE, PREV_PROJECT_PAGE, PROJECT_INDEX, ROOM} from "../utils/Contants";
import {showData} from "../modules/ShowData";
import {CURSOR_MINUS_3D, cursorManager} from "../modules/CursorManager";
import {WindowManager} from "../utils/WindowManager";
import {gallery3d} from "../modules/gallery/Gallery3d";
import {room3d} from "../modules/gallery/Room3d";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {colorizer, THEME_DARK} from "../modules/Colorizer";
import {PROJECT_INDEX_SELECT} from "./ProjectIndexPage";
import {Dropdown, DROPDOWN_SELECT} from "../components/Dropdown";

export class ProjectPage extends EditorialPage {

	private slug: string;
	private projectData: any;
	private projectClose: HTMLElement;
	private projectCloseAreas: NodeList;
	private breadcrumbs: HTMLElement;
	private sectionLinks: NodeListOf<HTMLElement>;
	private sectionModules: NodeListOf<HTMLElement>;
	private sectionsDropdown: Dropdown;
	private onMouseEnter: any = this._onMouseEnter.bind(this);
	private onMouseLeave: any = this._onMouseLeave.bind(this);
	private onCloseClick: any = this._onCloseClick.bind(this);
	private leftArrow: HTMLElement;
	private rightArrow: HTMLElement;
	private inView3d: boolean = true;
	private scrollWrapper: HTMLElement;
	private projectWrapper: HTMLElement;
	private projectBar: HTMLElement;
	private projectBarHeight: number = 0;
	private buttonDown: HTMLElement;
	private closeButton: HTMLElement;

	constructor(element: HTMLElement) {
		super(element);

		this.slug = state.getValue(CURRENT_OVERLAY).projectSlug;
		this.projectClose = this.element.querySelector('.project-close');
		this.projectCloseAreas = this.element.querySelectorAll('.project-close .hitter');
		this.breadcrumbs = this.element.querySelector('.breadcrumbs');
		this.scrollWrapper = this.element.querySelector('.scroll-wrapper');
		this.projectBar = this.element.querySelector('.project-bar');
		this.projectWrapper = this.element.querySelector('.project-wrapper');
		this.buttonDown = this.element.querySelector('.project-bar .down');
		this.leftArrow = this.element.querySelector('.previous-project');
		this.rightArrow = this.element.querySelector('.next-project');
		this.closeButton = this.element.querySelector('.close-button');

		this.leftArrow.addEventListener('click', () => {
			room3d.pendingAction = PREV_PROJECT_PAGE;
			this.close();
		});

		this.rightArrow.addEventListener('click', () => {
			room3d.pendingAction = NEXT_PROJECT_PAGE;
			this.close();
		});

		this.closeButton.addEventListener('click', (e) => {
			e.preventDefault();
			this.close();
		});

		// Section modules
		if (this.element.querySelector('#section-dropdown')) {
			this.sectionsDropdown = new Dropdown(this.element.querySelector('#section-dropdown'));
			this.sectionLinks = this.element.querySelectorAll('.section-links button');
			this.sectionModules = this.element.querySelectorAll('.SectionModule');

			this.sectionLinks.forEach((button, index) => {
				button.addEventListener('click', () => {
					this.scrollToSection(index);
				});
			}, this);
			this.sectionsDropdown.on(DROPDOWN_SELECT, (e) => {
				this.scrollToSection(e.value);
			});
		}

		this.projectBarHeight = parseInt(getComputedStyle(this.element).getPropertyValue('--project-bar-height'));

		// Fill parent data client-side
		this.projectData = showData.getProjectBySlug(this.slug);
		// if (!this.projectData) {
		// 	console.warn('ProjectPage: Project Data not found for this slug');
		// } else if (this.breadcrumbs) {
		// 	this.breadcrumbs.querySelector('.programme').innerHTML = this.projectData.programme.shortTitle;
		// }
		// if (this.projectData) {
		// 	this.element.querySelector('.programme .value').innerHTML = this.projectData.programme.title;
		// }

		gsap.registerPlugin(ScrollToPlugin);
	}

	public addEvents() {
		super.addEvents();
		if (this.buttonDown) {
			this.buttonDown.addEventListener('click', this.scrollDown.bind(this));
		}
		this.projectCloseAreas.forEach((area) => {
			area.addEventListener('mouseenter', this.onMouseEnter);
			area.addEventListener('mouseleave', this.onMouseLeave);
			area.addEventListener('click', this.onCloseClick);
		});
		WindowManager.signalResize.add(this.onResize.bind(this));
	}

	public removeEvents() {
		super.removeEvents();
		if (this.buttonDown) {
			this.buttonDown.removeEventListener('click', this.scrollDown.bind(this));
		}
		this.projectCloseAreas.forEach((area) => {
			area.removeEventListener('mouseenter', this.onMouseEnter);
			area.removeEventListener('mouseleave', this.onMouseLeave);
			area.removeEventListener('click', this.onCloseClick);
			WindowManager.signalResize.remove(this.onResize.bind(this));
		});
	}

	private scrollToSection(index) {
		const top = this.sectionModules[index].offsetTop + WindowManager.height;
		const offset = 10;

		this.sectionLinks.forEach((button, i) => {
			if (index === i) {
				button.classList.add('active');
			} else {
				button.classList.add('non-active');
			}
		}, this);

		gsap.to(this.element, {duration: .8, scrollTo: { y: top + offset}, ease: 'Power4.easeInOut', onComplete: () => {
				this.sectionLinks.forEach((button) => {
						button.classList.remove('non-active');
				}, this);
			}});
	}

	private scrollDown() {
		gsap.to(this.element, {duration: .8, scrollTo: { y: window.innerHeight }, ease: 'Power4.easeInOut'});
	}

	private _onMouseEnter() {
		cursorManager.setType(CURSOR_MINUS_3D);
	}

	private _onMouseLeave() {
		cursorManager.setType();
	}

	public animateIn() {
		const ease = 'Power2.easeOut';
		const duration = .5;

		if (this.projectBar.classList.contains('sticky')) {
			gsap.to(this.projectBar, {
				duration,
				bottom: 0,
				ease
			});
		} else {
			this.projectWrapper.setAttribute('will-change', 'transform');
			gsap.to(this.projectWrapper, {
				duration,
				y: -this.projectBarHeight,
				ease,
				clearProps: 'transform',
				onComplete: () => {
					this.projectWrapper.removeAttribute('will-change');
					this.projectWrapper.style.marginTop = `-${this.projectBarHeight}px`;
				}
			});
		}

		return super.animateIn();
	}

	public onResize() {
		super.onResize();
		this.projectClose.style.height = `${window.innerHeight}px`;
	}

	public onScroll() {
		super.onScroll();
		const bottom = this.scrollWrapper.clientHeight - (WindowManager.height + 10);
		// if page is showing header image (top or bottom)
		if (!this.inView3d &&
			(this.element.scrollTop < WindowManager.height - this.projectBarHeight ||
			this.element.scrollTop > bottom)) {
			this.inView3d = true;
			colorizer.changeColor(this.headerColor);
			gallery3d.startAnimation();
			if (this.element.scrollTop > bottom) {
				this.showArrows();
			}
		// if page is NOT showing header image (top or bottom)
		} else if (this.inView3d &&
			(this.element.scrollTop > WindowManager.height - this.projectBarHeight &&
			this.element.scrollTop < bottom)) {
			this.inView3d = false;
			colorizer.changeColor(this.bodyColor);
			gallery3d.stopAnimation();
			this.hideArrows();
		}

		// Section modules
		if (this.sectionModules) {
			this.sectionModules.forEach((sectionModule, index) => {
				if (sectionModule.getBoundingClientRect().top <= 0) {
					this.sectionLinks.forEach((sectionLink) => {
						sectionLink.classList.remove('active');
					});
					this.sectionLinks[index].classList.add('active');
				}
			});
		}
	}

	private _onCloseClick() {
		this.close();
	}

	private showArrows() {
		const overlay = state.getValue(LAST_OVERLAY);
		if (!overlay || overlay.id != PROJECT_INDEX) {
			gsap.to([this.leftArrow, this.rightArrow], .2, {autoAlpha: 1});
		}
	}

	private hideArrows() {
		gsap.to([this.leftArrow, this.rightArrow], .2, {autoAlpha: 0});
	}

	private close() {
		if (state.getValue(PROJECT_INDEX_SELECT)) {
			router.route(BASE_PATH + 'project-index');
		} else {
			const previous3dPath = state.getValue(LAST_3D_PATH);
			if (!previous3dPath) {
				router.route(BASE_PATH);
			} else {
				router.route(previous3dPath);
			}
		}
		state.setValue(PROJECT_INDEX_SELECT, false);
	}

	public destroy() {
		super.destroy();
		this.leftArrow.removeEventListener('click', () => {});
		this.rightArrow.removeEventListener('click', () => {});
		this.closeButton.removeEventListener('click', () => {});

		if (this.element.querySelector('#section-dropdown')) {
			this.sectionLinks.forEach((button, index) => {
				button.removeEventListener('click', () => {
					this.scrollToSection(index);
				});
			}, this);
			this.sectionsDropdown.off(DROPDOWN_SELECT, (e) => {
				this.scrollToSection(e.value);
			});
		}
	}

	public hide() {
		super.hide();
		state.setValue(PROJECT_INDEX_SELECT, false);
	}
}
