import Pusher from 'pusher-js';
import { state } from '../Main';

export class MultiuserPusher {
	public pusher;

	initialize() {
		//@ts-ignore
		this.pusher = new Pusher(pusher_key, { cluster: pusher_cluster });
	}
}

export const multiuserPusher = new MultiuserPusher();
