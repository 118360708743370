import {View} from "../modules/View";
import gsap from "gsap";
import {WindowManager} from "../utils/WindowManager";

export class SlidePage extends View {
	public animateIn() {
		if (this.animatedIn) {
			return;
		}
		this.animatedIn = true;
		this.show();

		return new Promise((resolve) => {
			gsap.set(this.element, {y: WindowManager.height});
			gsap.to(this.element, .7, {
				y: 0,
				clearProps: 'y',
				ease: 'Power2.easeInOut',
				onComplete: () => {
					resolve();
					this.animateInComplete();
				}
			});
		});
	}

	public animateOut() {
		if (!this.animatedIn) {
			return;
		}
		this.animatedIn = false;
		return new Promise((resolve) => {
			gsap.to(this.element, .7, {
				y: WindowManager.height,
				ease: 'Power2.easeInOut',
				onComplete: () => {
					resolve();
					this.hide();
					this.animateOutComplete();
				}
			});
		});
	}
}
