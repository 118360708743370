import { WindowManager } from '../../utils/WindowManager';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import { preload } from '../../utils/Preload';
import { Point } from '../../utils/Point';
import { DraggableModule } from './DraggableModule';
import { offset } from '../../utils/Helpers';

export class PanZoomModule extends DraggableModule {
	readonly marginX: number = 50;
	readonly marginY: number = 0;

	private imgDetailEl: HTMLImageElement;
	private posterEl: HTMLImageElement;
	private canvas: HTMLCanvasElement;
	private closeButton: HTMLElement;
	private loadingEl: HTMLElement;
	private context: CanvasRenderingContext2D;
	private imageAspect: number;
	private scale: number = 1;
	private targetCanvasSizeZoomedIn: Point = new Point(0, 0);
	private targetCanvasSizeZoomedOut: Point = new Point(0, 0);
	private currentPosition: Point = new Point(0, 0);
	private imageSize: Point = new Point(0, 0);
	private _canvasWidth: number = 0;
	private _canvasHeight: number = 0;
	private zoomedIn: boolean;

	constructor(element: HTMLElement) {
		super(element, element.querySelector('canvas'));
		this.imgDetailEl = this.element.querySelector('img.detail');
		this.posterEl = this.element.querySelector('.poster-wrapper');
		this.closeButton = this.element.querySelector('.close');
		this.loadingEl = this.element.querySelector('.loading');
		this.canvas = element.querySelector('canvas');
		this.context = this.canvas.getContext('2d');
		this.context.imageSmoothingEnabled = false;

		this.posterEl.addEventListener('click', () => {
			this.select();
		});

		this.closeButton.addEventListener('click', () => {
			this.zoomOut();
		});

		gsap.registerPlugin(ScrollToPlugin);
	}

	private select() {
		this.pageCloseButtonEl.style.display = 'none';
		gsap.to(this.loadingEl, 0.5, {
			autoAlpha: 1,
			onComplete: () => {
				//gsap.set(this.pageEl, { scrollTo: offset(this.element, this.pageEl).top });
				this.pageEl.style.overflow = 'hidden';
				this.loadDetail();
			}
		});
	}

	private async loadDetail() {
		await preload.loadImage(this.imgDetailEl);
		gsap.to(this.loadingEl, 0.5, { autoAlpha: 0 });
		this.imageAspect = this.imgDetailEl.width / this.imgDetailEl.height;
		this.zoomIn();
	}

	private set canvasWidth(w: number) {
		this._canvasWidth = w;
		this.canvas.width = w * 2;
		this.canvas.style.width = w + 'px';
	}

	private get canvasWidth() {
		return this._canvasWidth;
	}

	private set canvasHeight(h: number) {
		this._canvasHeight = h;
		this.canvas.height = h * 2;
		this.canvas.style.height = h + 'px';
	}

	private get canvasHeight() {
		return this._canvasHeight;
	}

	public render() {
		if (!this.zoomedIn) {
			return;
		}
		this.currentPosition.x += (this.targetPosition.x - this.currentPosition.x) / 10;
		this.currentPosition.y += (this.targetPosition.y - this.currentPosition.y) / 10;
		this.drawImage();
	}

	public onResize() {
		if (!this.zoomedIn) {
			return;
		}

		if (this.imageAspect > 1) {
			this.targetCanvasSizeZoomedOut.y = WindowManager.height - this.marginY * 2;
			this.targetCanvasSizeZoomedOut.x = WindowManager.height * this.imageAspect - this.marginX * 2;
		} else {
			this.targetCanvasSizeZoomedOut.x = WindowManager.width - this.marginX * 2;
			this.targetCanvasSizeZoomedOut.y = WindowManager.width / this.imageAspect - this.marginY * 2;
		}
		this.targetCanvasSizeZoomedIn.x = WindowManager.width;
		this.targetCanvasSizeZoomedIn.y = WindowManager.height;
		this.canvasWidth = this.zoomedIn ? this.targetCanvasSizeZoomedIn.x : this.targetCanvasSizeZoomedOut.x;
		this.canvasHeight = this.zoomedIn ? this.targetCanvasSizeZoomedIn.y : this.targetCanvasSizeZoomedOut.y;

		this.drawImage();
	}

	private async zoomIn() {
		if (this.zoomedIn) {
			return;
		}

		this.onResize();

		this.zoomedIn = true;
		this.element.classList.add('zoomed');
		this.addDragEvents();

		gsap.to(this, 0, {
			canvasWidth: this.targetCanvasSizeZoomedIn.x,
			canvasHeight: this.targetCanvasSizeZoomedIn.y,
			ease: 'Power1.easeInOut',
			scale: 2,
			onUpdate: () => {
				this.onResize();
			}
		});
	}

	private zoomOut() {
		if (!this.zoomedIn) {
			return;
		}

		gsap.to(this.loadingEl, 0.5, {
			autoAlpha: 1,
			onComplete: () => {
				this.pageCloseButtonEl.style.display = 'block';
				this.pageEl.style.removeProperty('overflow');
				this.element.classList.remove('zoomed');
				gsap.to(this.loadingEl, 0.5, { autoAlpha: 0 });
			}
		});

		this.zoomedIn = false;
		this.removeDragEvents();
		gsap.to([this.targetPosition, this.currentPosition], 0, { x: 0, y: 0 });
	}

	private drawImage() {
		if (!this.zoomedIn) {
			return;
		}

		if (this.imageAspect > 1) {
			this.imageSize.y = this.canvas.height * this.scale;
			this.imageSize.x = this.canvas.height * this.imageAspect * this.scale;
		} else {
			this.imageSize.x = this.canvas.width * this.scale;
			this.imageSize.y = (this.canvas.width / this.imageAspect) * this.scale;
		}
		this.maxDrag.x = this.imageSize.x / 2 - WindowManager.width;
		this.maxDrag.y = this.imageSize.y / 2 - WindowManager.height;
		this.minDrag.x = -this.maxDrag.x;
		this.minDrag.y = -this.maxDrag.y;
		const x = (this.imageSize.x <= this.canvas.width ? 0 : this.currentPosition.x) + (this.canvas.width / 2 - this.imageSize.x / 2);
		const y = (this.imageSize.y <= this.canvas.height ? 0 : this.currentPosition.y) + (this.canvas.height / 2 - this.imageSize.y / 2);
		this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
		this.context.drawImage(this.imgDetailEl, x, y, this.imageSize.x, this.imageSize.y);
	}

	public destroy() {
		super.destroy();
	}
}
