import { EditorialModule } from './EditorialModule';

export class ShareModule extends EditorialModule {
	private shareCopy: string;
	private facebookEl: HTMLElement;
	private twitterEl: HTMLElement;
	private linkedinEl: HTMLElement;
	private copyLinkEl: HTMLElement;
	private copyLinkAlertEl: HTMLElement;

	constructor(element: HTMLElement) {
		super(element);

		this.element = element;
		this.shareCopy = this.element.dataset.description;

		this.facebookEl = this.element.querySelector('.facebook');
		this.twitterEl = this.element.querySelector('.twitter');
		this.linkedinEl = this.element.querySelector('.linkedin');
		this.copyLinkEl = this.element.querySelector('.copylink');
		this.copyLinkAlertEl = this.element.querySelector('.copylink .alert');

		this.load();
	}

	public load() {
		this.facebookEl.addEventListener('click', this.onFacebookClick.bind(this));
		this.twitterEl.addEventListener('click', this.onTwitterClick.bind(this));
		this.linkedinEl.addEventListener('click', this.onLinkedInClick.bind(this));
		//this.copyLinkEl.addEventListener('click', this.onCopyLinkClick.bind(this));
	}

	private onFacebookClick() {
		const w = 600,
			h = 350;
		const l = screen.width / 2 - w / 2;
		const t = screen.height / 2 - h / 2;
		const facebookWindow = window.open('https://www.facebook.com/sharer/sharer.php?u=' + document.URL, 'facebook-popup', 'height=' + h + ',width=' + w + ',left=' + l + ',top=' + t);
		if (facebookWindow.focus) {
			facebookWindow.focus();
		}
		return false;
	}

	private onTwitterClick() {
		const w = 600,
			h = 350;
		const l = screen.width / 2 - w / 2;
		const t = screen.height / 2 - h / 2;
		const twitterWindow = window.open('https://twitter.com/share?url=' + document.URL + '&text=' + this.shareCopy, 'twitter-popup', 'height=' + h + ',width=' + w + ',left=' + l + ',top=' + t);
		if (twitterWindow.focus) {
			twitterWindow.focus();
		}
		return false;
	}

	private onLinkedInClick() {
		const w = 600,
			h = 975;
		const l = screen.width / 2 - w / 2;
		const t = screen.height / 2 - h / 2;
		const linkedinWindow = window.open(
			'https://www.linkedin.com/shareArticle?mini=true&url=' + document.URL + '&source=LinkedIn&summary=' + this.shareCopy,
			'linkedin-popup',
			'height=' + h + ',width=' + w + ',left=' + l + ',top=' + t
		);
		if (linkedinWindow.focus) {
			linkedinWindow.focus();
		}
		return false;
	}

	private onCopyLinkClick() {
		const textArea = document.createElement('input');
		textArea.value = this.shareCopy;
		this.copyLinkEl.appendChild(textArea);
		textArea.select();
		try {
			var successful = document.execCommand('copy');
			var msg = successful ? 'successful' : 'unsuccessful';
		} catch (err) {
			console.log('Oops, unable to copy', err);
		}
		this.copyLinkEl.removeChild(textArea);

		TweenMax.to(this.copyLinkAlertEl, 0.3, { autoAlpha: 1 });
		TweenMax.to(this.copyLinkAlertEl, 0.3, { delay: 1.2, autoAlpha: 0 });

		return false;
	}

	public destroy() {
		this.facebookEl.removeEventListener('click', this.onFacebookClick.bind(this));
		this.twitterEl.removeEventListener('click', this.onTwitterClick.bind(this));
		this.linkedinEl.removeEventListener('click', this.onLinkedInClick.bind(this));
		//this.copyLinkEl.removeEventListener('click', this.onCopyLinkClick.bind(this));
	}
}
