import gsap from 'gsap';
import { View } from './View';
import { CURRENT_OVERLAY } from '../utils/Contants';
import { state } from '../Main';
import { WindowManager } from '../utils/WindowManager';

const TOGGLE_DEPTH_ACTIVE = '15';
const TOGGLE_DEPTH_INACTIVE = '11';

export class MainMenu extends View {
	private activeItem: HTMLElement;
	private closeMenuHitter: HTMLElement;
	private menuToggle: HTMLElement;
	private menuLinesOpen: NodeListOf<HTMLElement>;
	private menuLineClose: HTMLElement;
	private slidUp: boolean;
	private speed: number;
	private onResize: any = this._onResize.bind(this);

	constructor(element) {
		super(element);
		this.closeMenuHitter = this.element.querySelector('.close-menu-hitter');
		this.menuToggle = document.querySelector('.main-menu-toggle');
		this.menuLinesOpen = this.menuToggle.querySelectorAll('.menu-lines-open .menu-line');
		this.menuLineClose = this.menuToggle.querySelector('.menu-line-close');
		this.speed = 0.7;
	}

	initialize() {
		this.menuLinesOpen.forEach((line: HTMLElement) => {
			line.dataset.points = line.getAttribute('points');
		});

		state.on(CURRENT_OVERLAY, stateItem => {
			if (this.activeItem) {
				this.activeItem.classList.remove('active');
				this.activeItem = null;
			}

			if (stateItem.current) {
				this.activeItem = this.element.querySelector('#' + stateItem.current.id);
			}

			if (!this.activeItem && !stateItem.current) {
				this.activeItem = this.element.querySelector('#gallery');
			}

			if (this.activeItem) {
				this.activeItem.classList.add('active');
			}
		});

		this.closeMenuHitter.addEventListener('click', () => {
			this.animateOut();
		});

		this.menuToggle.addEventListener('click', () => {
			mainMenu.toggleAnimate();
		});

		WindowManager.signalResize.add(this.onResize);
	}

	private _onResize() {
		if (this.slidUp) {
			this.slideUp(0);
		}
	}

	public animateIn() {
		this.menuToggle.classList.add('active');
		this.menuToggle.style.zIndex = TOGGLE_DEPTH_ACTIVE;
		gsap.to(this.menuLinesOpen, {
			duration: 0.4,
			ease: 'Power2.easeOut',
			attr: { points: this.menuLineClose.getAttribute('points') }
		});
		return super.animateIn();
	}

	public animateOut(): undefined | Promise<unknown> {
		this.menuToggle.classList.remove('active');
		this.menuLinesOpen.forEach((line: HTMLElement) => {
			gsap.to(line, {
				duration: 0.4,
				ease: 'Power2.easeOut',
				attr: { points: line.dataset.points }
			});
		});
		return super.animateOut();
	}

	public animateOutComplete() {
		this.menuToggle.style.zIndex = TOGGLE_DEPTH_INACTIVE;
	}

	public animateInToggle() {
		gsap.to(this.menuToggle, 0.5, { autoAlpha: 1 });
	}

	public animateOutToggle() {
		gsap.to(this.menuToggle, 0.5, { autoAlpha: 0 });
	}

	public slideUp(speed = this.speed) {
		this.slidUp = true;
		this.menuToggle.style.zIndex = TOGGLE_DEPTH_INACTIVE;
		gsap.to([this.element, this.menuToggle], speed, { y: -WindowManager.height / 2, ease: 'Power2.easeInOut' });
	}

	public slideDown(speed = this.speed) {
		this.slidUp = false;
		gsap.to([this.element, this.menuToggle], speed, {
			y: 0,
			ease: 'Power2.easeInOut',
			onComplete: () => {
				this.menuToggle.style.zIndex = this.animatedIn ? TOGGLE_DEPTH_ACTIVE : TOGGLE_DEPTH_INACTIVE;
			}
		});
	}
}

export const mainMenu = new MainMenu(document.querySelector('.MainMenu'));
