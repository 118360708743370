import { EditorialModule } from './EditorialModule';
import { Point } from '../../utils/Point';
import { WindowManager } from '../../utils/WindowManager';
import { clamp } from '../../utils/Helpers';

export class DraggableModule extends EditorialModule {
	public draggableElement: HTMLElement;
	public targetPosition: Point = new Point(0, 0);
	public minDrag: Point = new Point(-Infinity, -Infinity);
	public maxDrag: Point = new Point(Infinity, Infinity);
	private onMouseDown: any = this._onMouseDown.bind(this);
	private onMouseUp: any = this._onMouseUp.bind(this);
	private onMouseMove: any = this._onMouseMove.bind(this);
	private onTouchStart: any = this._onTouchStart.bind(this);
	private onTouchMove: any = this._onTouchMove.bind(this);
	private onTouchEnd: any = this._onTouchEnd.bind(this);
	private dragging: boolean;
	private lastDragPoint: Point = new Point(0, 0);

	constructor(element, draggableElement) {
		super(element);
		this.draggableElement = draggableElement;
	}

	public addDragEvents() {
		this.removeDragEvents();
		this.draggableElement.addEventListener('mousedown', this.onMouseDown);
		this.draggableElement.addEventListener('mouseup', this.onMouseUp);
		this.draggableElement.addEventListener('mousemove', this.onMouseMove);
		this.draggableElement.addEventListener('touchstart', this.onTouchStart);
		this.draggableElement.addEventListener('touchmove', this.onTouchMove);
		this.draggableElement.addEventListener('touchend', this.onTouchEnd);
	}

	public removeDragEvents() {
		this.draggableElement.removeEventListener('mousedown', this.onMouseDown);
		this.draggableElement.removeEventListener('mouseup', this.onMouseUp);
		this.draggableElement.removeEventListener('mousemove', this.onMouseMove);
		this.draggableElement.removeEventListener('touchstart', this.onTouchStart);
		this.draggableElement.removeEventListener('touchmove', this.onTouchMove);
		this.draggableElement.removeEventListener('touchend', this.onTouchEnd);
	}

	private _onMouseDown(event) {
		this.startDrag();
	}

	private startDrag() {
		this.draggableElement.style.cursor = 'grabbing';
		this.dragging = true;
	}

	private _onMouseMove(event) {
		if (this.dragging) {
			this.onDrag(event.movementX * 2, event.movementY * 2);
		}
	}

	private _onTouchStart(event) {
		this.lastDragPoint.x = event.changedTouches[0].clientX;
		this.lastDragPoint.y = event.changedTouches[0].clientY;
		this.startDrag();
	}

	private _onTouchMove(event) {
		if (this.dragging) {
			const deltaX = event.changedTouches[0].clientX - this.lastDragPoint.x;
			const deltaY = event.changedTouches[0].clientY - this.lastDragPoint.y;
			this.onDrag(deltaX * 2, deltaY * 2);
			this.lastDragPoint.x = event.changedTouches[0].clientX;
			this.lastDragPoint.y = event.changedTouches[0].clientY;
		}
	}

	private _onTouchEnd(event) {
		this.stopDrag();
	}

	private onDrag(deltaX, deltaY) {
		this.targetPosition.x += deltaX;
		this.targetPosition.y += deltaY;
		this.targetPosition.x = clamp(this.minDrag.x, this.maxDrag.x, this.targetPosition.x);
		this.targetPosition.y = clamp(this.minDrag.y, this.maxDrag.y, this.targetPosition.y);
	}

	private _onMouseUp(event) {
		this.stopDrag();
	}

	private stopDrag() {
		this.draggableElement.style.cursor = 'grab';
		this.dragging = false;
	}

	public destroy() {
		super.destroy();
		this.removeDragEvents();
	}
}
