import Emitter from '@hellomonday/emitter';

export class Preload extends Emitter {
	public loadImage(element) {
		let attrSrc = 'src';
		let dataSrc = element.dataset[attrSrc];
		if (!dataSrc) {
			attrSrc = 'srcset';
			dataSrc = element.dataset[attrSrc];
		}
		if (!dataSrc) {
			return;
		}
		return new Promise(resolve => {
			element.onload = () => {
				resolve();
			};
			element.setAttribute(attrSrc, dataSrc);
			if (element) {
				element.removeAttribute('data-src');
			}
		});
	}
}
export const preload = new Preload();
