import gsap from 'gsap';
import { camera, gallery3d } from './Gallery3d';
import { BASE_PATH } from '../../utils/Contants';
import { WindowManager } from '../../utils/WindowManager';

export const degToRad = 180 / Math.PI;

const MAX_CONTACTS = 8;

export class FloorEntranceTitleCard {
	public element: HTMLElement;
	private titleCardEl: HTMLElement;

	constructor(unitData: any) {
		this.element = document.createElement('div');
		this.element.classList.add('FloorEntranceTitleCard', 'noselect');

		this.titleCardEl = document.createElement('div');
		this.titleCardEl.classList.add('title-card');
		this.element.appendChild(this.titleCardEl);

		const unitCodeEl = document.createElement('div');
		unitCodeEl.classList.add('unit-code');
		let unitCodeTitle = unitData.programme.title;
		if (unitData.code) {
			unitCodeTitle += ', ' + unitData.code;
		}
		unitCodeEl.innerHTML = unitCodeTitle;
		//this.titleCardEl.appendChild(unitCodeEl);

		const titleEl = document.createElement('div');
		titleEl.classList.add('title');
		titleEl.innerHTML = unitData.title;
		this.titleCardEl.appendChild(titleEl);

		const contactsEl = document.createElement('div');
		const contacts = unitData.programmeDirectors;
		contactsEl.classList.add('contacts');
		contacts.forEach((contact: any, index) => {
			if (index < MAX_CONTACTS) {
				contactsEl.innerHTML += '<span>' + contact.name + '</span>';
				if (index < contacts.length - 1) {
					contactsEl.innerHTML += ', ';
				}
			}
		});
		if (unitData.programmeDirectors.length > MAX_CONTACTS) {
			contactsEl.innerHTML += ' and more.';
		}
		this.titleCardEl.appendChild(contactsEl);

		const buttonEl = document.createElement('a');
		buttonEl.classList.add('pill-button');
		buttonEl.setAttribute('href', BASE_PATH + 'room/' + unitData.slug);
		buttonEl.innerHTML = 'View work';
		this.titleCardEl.appendChild(buttonEl);
	}

	public render() {
		gsap.set(this.titleCardEl, {
			rotationY: -camera.rotation.y * degToRad,
			rotationX: camera.rotation.x * degToRad
		});
	}

	public resize() {
		this.element.style.perspective = gallery3d.cssFOV.toString() + 'px';
		this.element.style.height = WindowManager.height + 'px';
	}

	public show() {
		this.element.style.visibility = 'visible';
	}

	public hide() {
		this.element.style.visibility = 'hidden';
	}
}
