import { EditorialModule } from './EditorialModule';
import videojs from 'video.js';

export class VideoModule extends EditorialModule {
	public mediaElement: HTMLMediaElement;

	constructor(element: HTMLElement) {
		super(element);

		this.mediaElement = element.querySelector('.video-js');

		if (this.mediaElement) {
			videojs(this.mediaElement, {}, () => {});

			this.mediaElement.addEventListener('play', () => {
				this.emit('pausePlayers', { el: this.mediaElement });
			});
		}
	}

	public play() {
		if (this.mediaElement) {
			this.mediaElement.play();
		}
	}

	public pause() {
		if (this.mediaElement) {
			this.mediaElement.pause();
		}
	}

	public load() {}

	public destroy() {
		if (this.mediaElement) {
			videojs(this.mediaElement).dispose();
		}
	}
}
