import Emitter from '@hellomonday/emitter';

export const UNBLOCK = 'unblock';

class ClickBlocker extends Emitter {
	public blocked: boolean;
	private clickBlocker: HTMLElement;
	private blockCount: number = 0;

	constructor(element: HTMLElement) {
		super();
		this.clickBlocker = element;
	}

	block() {
		this.blocked = true;
		this.blockCount++;
		this.clickBlocker.style.display = 'block';
	}

	unblock() {
		this.blockCount--;
		if (this.blockCount == 0) {
			this.blocked = false;
			this.clickBlocker.style.display = 'none';
			this.emit(UNBLOCK, {});
		}
	}
}

export const clickBlocker = new ClickBlocker(document.body.querySelector('.ClickBlocker'));
